<script lang="ts">
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    urlLink: {
      type: String,
      default: 'dashboard',
    },
  },
})
</script>

<template>
  <div class="rounded-card bg-white overflow-auto flex flex-col justify-start">
    <nuxt-img
      provider="s3"
      :src="image"
      alt="Image of {{ title }}"
      class="object-cover w-full"
      sizes="(max-width: 768px) 90vw, (max-width: 1200px) 50vw, 33vw"
      style="height: 300px"
      loading="lazy"
    />
    <div class="p-6 flex md:h-full flex-col flex-nowrap justify-between">
      <div>
        <h4 class="text-2xl font-bold mb-4">{{ title }}</h4>
        <div class="text-base mb-8">{{ description }}</div>
      </div>
      <Anchor
        :href="urlLink"
        :to="urlLink"
        class="btn-base fill-yellow hover:no-underline ease-in-out duration-150"
        title="Order"
        ><font-awesome-icon :icon="['fas', 'cart-shopping']" class="mr-2" />{{
          $t('buttons.toOrder')
        }}</Anchor
      >
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
